import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`30th January 2024`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`xDS-based EndpointGroup`}</strong>{`: You can utilize `}<a parentName="p" {...{
            "href": "https://www.envoyproxy.io/docs/envoy/latest/api-docs/xds_protocol"
          }}>{`xDS protocol`}</a>{`
to create `}<a parentName="p" {...{
            "href": "type://XdsEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/xds/client/endpoint/XdsEndpointGroup.html"
          }}>{`type://XdsEndpointGroup`}</a>{` for service discovery. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5342"
          }}>{`#5342`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5336"
          }}>{`#5336`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`XdsBootstrap bootstrap = XdsBootstrap.of(...);
EndpointGroup endpointGroup = XdsEndpointGroup.of(boostrap.listenerRoot("my-envoy-service"));
WebClient client = WebClient.of(SessionProtocol.HTTP, endpointGroup);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`GraphQL Subscriptions over WebSocket`}</strong>{`: You can now enable `}<a parentName="p" {...{
            "href": "https://github.com/enisdenjo/graphql-ws/blob/master/PROTOCOL.md"
          }}>{`GraphQL subscriptions over WebSocket`}</a>{`
for live updates to your clients. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4999"
          }}>{`#4999`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5280"
          }}>{`#5280`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`GraphqlService
  .builder()
  .enableWebSocket(true)
  .webSocketServiceCustomizer(ws -> {
    // Customize the underlying WebSocket service.
  })
  .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Service-specific EventLoopGroup`}</strong>{`: You can now assign a different `}<inlineCode parentName="p">{`EventLoopGroup`}</inlineCode>{` to each service for
finer grained resource control. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4099"
          }}>{`#4099`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5233"
          }}>{`#5233`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server
  .builder()
  .serviceWorkerGroup(globalServiceWorkerGroup)
  .route()
     .path("/user")
     // Use userServiceWorkerGroup for the user service.
     .serviceWorkerGroup(userServiceWorkerGroup)
     .build(userService)
  .route()
     .path("/api")
     // Use apiServiceWorkerGroup for the API service.
     .serviceWorkerGroup(apiServiceWorkerGroup)
     .build(apiService)
  .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Thrift Service Decorators`}</strong>{`: You can now use `}<a parentName="p" {...{
            "href": "type://@Decorator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Decorator.html"
          }}>{`type://@Decorator`}</a>{` annotations to conveniently apply
custom decorators to Thrift services. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4230"
          }}>{`#4230`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5356"
          }}>{`#5356`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`@Decorator(ClassDecorator.class)
class MyHelloService implements HelloService.AsyncIface {
  @Decorator(MethodDecorator.class)
  @Override
  public void hello(String name, AsyncMethodCallback<String> handler) throws TException {
    ...
  }
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Sensitive Header Masking`}</strong>{`: You can now easily mask request and response headers
using `}<a parentName="p" {...{
            "href": "type://HeadersSanitizer:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/HeadersSanitizer.html"
          }}>{`type://HeadersSanitizer`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5175"
          }}>{`#5175`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5188"
          }}>{`#5188`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HeadersSanitizer<String> headersSanitizer =
 HeadersSanitizer
   .builderForText()
   .sensitiveHeaders("Authorization", "API-Key", "Proxy-Authorization")
   ...
   .build();

LogFormatter
  .builderForText()
  .requestHeadersSanitizer(headersSanitizer)
  ...
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Custom Exception Handling for gRPC clients`}</strong>{`: You can customize how your gRPC clients respond to exceptions
by implementing `}<a parentName="p" {...{
            "href": "type://GrpcExceptionHandlerFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/grpc/GrpcExceptionHandlerFunction.html"
          }}>{`type://GrpcExceptionHandlerFunction`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5347"
          }}>{`#5347`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5351"
          }}>{`#5351`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`GrpcClients
  .builder("https://my-grpc-server.com") 
  .exceptionHandler((ctx, cause, metadata) -> {
    // Handle the exception.
    return Status.INTERNAL.withDescription("Something went wrong");
  })
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Kubernetes Client Integration`}</strong>{`: You can now use the popular `}<a parentName="p" {...{
            "href": "https://github.com/fabric8io/kubernetes-client"
          }}>{`Fabric Kubernetes client`}</a>{`
seamlessly on top of Armeria when using the `}<inlineCode parentName="p">{`armeria-kubernetes`}</inlineCode>{` module. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5167"
          }}>{`#5167`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`WebSocket Service Fallback`}</strong>{`: You can now set up a fallback service for
`}<a parentName="p" {...{
            "href": "type://WebSocketService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/websocket/WebSocketService.html"
          }}>{`type://WebSocketService`}</a>{` to handle both WebSocket and non-WebSocket requests on the same path with
`}<a parentName="p" {...{
            "href": "type://WebSocketServiceBuilder#fallbackService(HttpService):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/websocket/WebSocketServiceBuilder.html#fallbackService(com.linecorp.armeria.server.HttpService)"
          }}>{`type://WebSocketServiceBuilder#fallbackService(HttpService)`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5368"
          }}>{`#5368`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`WebSocketService webSocketService = 
  WebSocketService
    .builder()
    // The fallback service is used when the request
    // is not a WebSocket request.
    .fallbackService(httpService)
    .build();
Server
  .builder()
  .service("/ws-or-http", webSocketService)
  .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`WebSocket Frame Aggregation`}</strong>{`: You can now enable to aggregate WebSocket continuation frames for simplified
message processing using `}<a parentName="p" {...{
            "href": "type://WebSocketServiceBuilder#aggregateContinuation(boolean):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/websocket/WebSocketServiceBuilder.html#aggregateContinuation(boolean)"
          }}>{`type://WebSocketServiceBuilder#aggregateContinuation(boolean)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5357"
          }}>{`#5357`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`WebSocketService
  .builder()
  // Aggregate continuation frames into a single frame.
  .aggregateContinuation(true)
  .build(); 
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Active Connection Monitoring`}</strong>{`: You can monitor the number of active connections with
`}<inlineCode parentName="p">{`armeria.client.connections#value{state="active"}`}</inlineCode>{` for your clients when using
`}<a parentName="p" {...{
            "href": "type://ConnectionPoolListener#metricCollecting(MeterRegistry):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ConnectionPoolListener.html#metricCollecting(io.micrometer.core.instrument.MeterRegistry)"
          }}>{`type://ConnectionPoolListener#metricCollecting(MeterRegistry)`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4277"
          }}>{`#4277`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5288"
          }}>{`#5288`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ConnectionPoolListener connectionPoolListener =
  ConnectionPoolListener.metricCollecting(meterRegistry);

ClientFactory clientFactory =
  ClientFactory
    .builder()
    .connectionPoolListener(connectionPoolListener)
    .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Graceful Executor Shutdown`}</strong>{`: You can now gracefully terminate a custom `}<inlineCode parentName="p">{`ExecutorService`}</inlineCode>{` in sync with the
`}<a parentName="p" {...{
            "href": "type://Server:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Server.html"
          }}>{`type://Server`}</a>{`'s shutdown procedure. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5031"
          }}>{`#5031`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5087"
          }}>{`#5087`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ServerListener
  .builder()
  // Shutdown the executor service when the server is stopping.
  .shutdownWhenStopping(preStopExecutorService)
  // Shutdown the executor service when the server is stopped.
  .shutdownWhenStopped(postStopExecutorService)
  .build()
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Session Protocol in RoutingContext`}</strong>{`: You can now acquire `}<a parentName="p" {...{
            "href": "type://SessionProtocol:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/SessionProtocol.html"
          }}>{`type://SessionProtocol`}</a>{` directly from
`}<a parentName="p" {...{
            "href": "type://RoutingContext#sessionProtocol():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/RoutingContext.html#sessionProtocol()"
          }}>{`type://RoutingContext#sessionProtocol()`}</a>{` for enhanced routing and `}<a parentName="p" {...{
            "href": "type://ExchangeType:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ExchangeType.html"
          }}>{`type://ExchangeType`}</a>{` decisions.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Eureka Health Check Customization`}</strong>{`: You can override the default health check URL when registering a service
with Eureka. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5369"
          }}>{`#5369`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`EurekaUpdatingListener
  .builder()
  // Override the default health check path provided by HealthCheckService.
  .healthCheckUrlPath("/healthcheck")
  .build();
`}</code></pre>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul">{`Annotated services now support `}<a parentName="li" {...{
          "href": "type://HttpResult:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/HttpResult.html"
        }}>{`type://HttpResult`}</a>{` wrapped in RxJava objects. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5380"
        }}>{`#5380`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5386"
        }}>{`#5386`}</a></li>
      <li parentName="ul">{`You can now create `}<a parentName="li" {...{
          "href": "type://HttpFile:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/HttpFile.html"
        }}>{`type://HttpFile`}</a>{` with
`}<a parentName="li" {...{
          "href": "https://docs.spring.io/spring-boot/docs/current/reference/html/executable-jar.html#appendix.executable-jar.jarfile-class.compatibility"
        }}>{`Spring Boot nested JAR URL`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5390"
        }}>{`#5390`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5391"
        }}>{`#5391`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://WebSocketService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/websocket/WebSocketService.html"
        }}>{`type://WebSocketService`}</a>{` is restructured as an interface, allowing for custom implementations. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5368"
        }}>{`#5368`}</a></li>
      <li parentName="ul">{`Introduce `}<a parentName="li" {...{
          "href": "type://PreTlsHandshakeException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/PreTlsHandshakeException.html"
        }}>{`type://PreTlsHandshakeException`}</a>{` to better report exceptions occurring before a TLS handshake
initiates. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5344"
        }}>{`#5344`}</a></li>
      <li parentName="ul">{`Error messages for gRPC client stub generation have been clarified. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5387"
        }}>{`#5387`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`The completions of `}<a parentName="li" {...{
          "href": "type://ClientRequestContext#whenResponseCancelling():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientRequestContext.html#whenResponseCancelling()"
        }}>{`type://ClientRequestContext#whenResponseCancelling()`}</a>{` and `}<a parentName="li" {...{
          "href": "type://ClientRequestContext#whenResponseCancelled():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientRequestContext.html#whenResponseCancelled()"
        }}>{`type://ClientRequestContext#whenResponseCancelled()`}</a>{`
are now guaranteed even if `}<a parentName="li" {...{
          "href": "type://RequestContext#cancel():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html#cancel()"
        }}>{`type://RequestContext#cancel()`}</a>{` is invoked before the initialization of
the timeout scheduler. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5212"
        }}>{`#5212`}</a></li>
      <li parentName="ul">{`Fixed a leak within `}<a parentName="li" {...{
          "href": "type://WebSocketClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/websocket/WebSocketClient.html"
        }}>{`type://WebSocketClient`}</a>{` when an invalid response is received. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5412"
        }}>{`#5412`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://protobuf.com/docs/language-spec#default-json-names"
        }}><inlineCode parentName="a">{`json_name`}</inlineCode>{` pseudo-option`}</a>{` in protobuf files is
now correctly respected for matching query parameters during gRPC HTTP/JSON transcoding. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5193"
        }}>{`#5193`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5366"
        }}>{`#5366`}</a></li>
      <li parentName="ul">{`Implicit request objects are now correctly converted. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5389"
        }}>{`#5389`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5393"
        }}>{`#5393`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://CloseWebSocketFrame:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/websocket/CloseWebSocketFrame.html"
        }}>{`type://CloseWebSocketFrame`}</a>{` with empty reason phrases are now correctly handled. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5388"
        }}>{`#5388`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://Flags:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Flags.html"
        }}>{`type://Flags`}</a>{` now correctly logs all messages when `}<a parentName="li" {...{
          "href": "type://RequestContextExportingAppender:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logback/RequestContextExportingAppender.html"
        }}>{`type://RequestContextExportingAppender`}</a>{` is configured in
`}<inlineCode parentName="li">{`logback.xml`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5327"
        }}>{`#5327`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5361"
        }}>{`#5361`}</a></li>
      <li parentName="ul">{`You no longer see `}<a parentName="li" {...{
          "href": "type://EndpointSelectionTimeoutException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointSelectionTimeoutException.html"
        }}>{`type://EndpointSelectionTimeoutException`}</a>{` when `}<a parentName="li" {...{
          "href": "type://HealthCheckedEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/healthcheck/HealthCheckedEndpointGroup.html"
        }}>{`type://HealthCheckedEndpointGroup`}</a>{` is
initialized with duplicate `}<a parentName="li" {...{
          "href": "typeplural://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
        }}>{`typeplural://Endpoint`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5343"
        }}>{`#5343`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://HttpHeaders#containsBoolean(CharSequence,boolean):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpHeaders.html#containsBoolean(java.lang.CharSequence,boolean)"
        }}>{`type://HttpHeaders#containsBoolean(CharSequence,boolean)`}</a>{` now checks all header entries with the same
name. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5319"
        }}>{`#5319`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
        }}>{`type://GrpcService`}</a>{` provides more consistent `}<inlineCode parentName="li">{`ServerCall.Listener`}</inlineCode>{` behavior under error conditions. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5273"
        }}>{`#5273`}</a></li>
    </ul>
    <h2 {...{
      "id": "-documentation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-documentation",
        "aria-label": " documentation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📃 Documentation`}</h2>
    <ul>
      <li parentName="ul">{`Updated `}<a parentName="li" {...{
          "href": "/docs/advanced-production-checklist"
        }}>{`Production Checklist`}</a>{` with guidance on
using `}<a parentName="li" {...{
          "href": "type://ClientFactoryBuilder#maxNumEventLoopsPerEndpoint(int):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactoryBuilder.html#maxNumEventLoopsPerEndpoint(int)"
        }}>{`type://ClientFactoryBuilder#maxNumEventLoopsPerEndpoint(int)`}</a>{`. This addition will help you mitigate
potential client-side bottlenecks. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5297"
        }}>{`#5297`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-deprecations",
        "aria-label": "️ deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🏚️ Deprecations`}</h2>
    <ul>
      <li parentName="ul">{`N/A`}</li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul">{`N/A`}</li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Apache Curator 5.5.0 → 5.6.0`}</li>
      <li parentName="ul">{`Brave 5.16.0 → 6.0.0`}</li>
      <li parentName="ul">{`Brotli4j 1.12.0 → 1.15.0`}</li>
      <li parentName="ul">{`Envoy control plane 1.0.42 (new)`}</li>
      <li parentName="ul">{`Dropwizard 2.1.8 → 2.1.10`}</li>
      <li parentName="ul">{`Dropwizard Metrics 4.2.21 → 4.2.24`}</li>
      <li parentName="ul">{`GraphQL Java 20.4 → 21.3`}</li>
      <li parentName="ul">{`Fabric Kubernetes client 6.10.0 (new)`}</li>
      <li parentName="ul">{`gRPC Java 1.58.0 → 1.61.0`}</li>
      <li parentName="ul">{`gRPC Kotlin 1.4.0 → 1.4.1`}</li>
      <li parentName="ul">{`Guava 32.1.3 → 33.0.0`}</li>
      <li parentName="ul">{`Jackson 2.15.3 → 2.16.1`}</li>
      <li parentName="ul">{`Jetty 9.4.51 → 9.4.52, 10.0.17 → 10.0.19, 11.0.17 → 11.0.19, 12.0.5 (new)`}</li>
      <li parentName="ul">{`JUnit 5.10.0 → 5.10.1`}</li>
      <li parentName="ul">{`Kafka client 3.6.0 → 3.6.1`}</li>
      <li parentName="ul">{`Kotlin 1.9.0 → 1.9.22`}</li>
      <li parentName="ul">{`krotoDC 1.0.5 - 1.0.6`}</li>
      <li parentName="ul">{`Logback 1.2.11 → 1.2.13, 1.3.11 → 1.3.14, 1.4.11 → 1.4.14`}</li>
      <li parentName="ul">{`Micrometer 1.11.5 → 1.12.2`}</li>
      <li parentName="ul">{`Micrometer Tracing 1.1.6 → 1.2.2`}</li>
      <li parentName="ul">{`Netty 4.1.100 → 4.1.106`}</li>
      <li parentName="ul">{`Netty io_uring 0.0.23 → 0.0.24`}</li>
      <li parentName="ul">{`Protobuf 3.24.0 → 3.25.1`}</li>
      <li parentName="ul">{`Reactor 3.5.11 → 3.6.2`}</li>
      <li parentName="ul">{`Resilience4j 2.1.0 → 2.2.0`}</li>
      <li parentName="ul">{`ScalaPB 0.11.13 → 0.11.15`}</li>
      <li parentName="ul">{`Spring 6.0.11 → 6.1.3`}</li>
      <li parentName="ul">{`Spring Boot 2.7.14 → 2.7.18, 3.1.2 → 3.2.2`}</li>
      <li parentName="ul">{`Tomcat 8.5.94 → 8.5.98, 9.0.82 → 9.0.85, 10.1.15 → 10.1.18`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['0x1306e6d', 'Dogacel', 'KarboniteKream', 'Lincong', 'andreaTP', 'chaahc', 'cj848', 'codefromthecrypt', 'eottabom', 'gibeom-gwon', 'ikhoon', 'imasahiro', 'injae-kim', 'jrhee17', 'kezhenxu94', 'lujiajing1126', 'minwoox', 'mynameisjwlee', 'seonwoo960000', 'spacecowboy', 'tomatophobia', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      